exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-class-tsx": () => import("./../../../src/pages/class.tsx" /* webpackChunkName: "component---src-pages-class-tsx" */),
  "component---src-pages-gallery-report-tsx": () => import("./../../../src/pages/gallery/report.tsx" /* webpackChunkName: "component---src-pages-gallery-report-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-seniorlife-tsx": () => import("./../../../src/pages/seniorlife.tsx" /* webpackChunkName: "component---src-pages-seniorlife-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */)
}

